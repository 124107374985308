import { create } from "zustand";

export interface Message {
  content: string;
  role: "user" | "assistant";
  conversationId: string;
  createdAt?: string;
}

interface MessageState {
  messages: Message[];
  userMediaStream: MediaStream | null;
  showFeedback: boolean;
  showLeadCapture?: boolean;
  voiceChat: boolean;
  historyMode: boolean;
  transcribe: boolean;
  error: any;
  setError: (error: any) => void;
  setTranscribe: (transcribe: boolean) => void;
  setHistoryMode: (historyMode: boolean) => void;
  setVoiceChat: (voiceChat: boolean) => void;
  setMediaStream: (mediaStream: MediaStream | null) => void;
  setMessages: (messages: any[]) => void;
  setShowFeedback: (showFeedback: boolean) => void;
  setShowLeadCapture: (showLeadCapture: boolean | undefined) => void;
}

const useMessages = create<MessageState>((set) => ({
  messages: [],
  showFeedback: false,
  showLeadCapture: undefined,
  userMediaStream: null,
  voiceChat: false,
  historyMode: false,
  transcribe: false,
  error: null,
  setTranscribe: (transcribe) => set({ transcribe }),
  setHistoryMode: (historyMode) => set({ historyMode }),
  setVoiceChat: (voiceChat) => set({ voiceChat }),
  setMediaStream: (mediaStream) => set({ userMediaStream: mediaStream }),
  setShowFeedback: (showFeedback) => set({ showFeedback }),
  setShowLeadCapture: (showLeadCapture) => set({ showLeadCapture }),
  setMessages: (messages) => set({ messages }),
  setError: (error) => set({ error }),
}));

export default useMessages;
