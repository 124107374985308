import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface Store {
  oThreadId: string | "undefined";
  threadId: string | "undefined";
  setThreadId: (threadId: string | "undefined") => void;
  setOThreadId: (oThreadId: string | "undefined") => void;
  fromExisting: boolean;
  setFromExisting: (fromExisting: boolean) => void;
}

const useStore = create<Store>()(
  devtools(
    (set) => ({
      threadId: "undefined",
      oThreadId: "undefined",
      fromExisting: false,
      setOThreadId: (oThreadId) => {
        if (oThreadId !== "undefined" && !oThreadId.startsWith("thread_")) {
          throw new Error("Invalid oThreadId");
        }
        set({ oThreadId });
      },
      setThreadId: (threadId) => {
        if (threadId !== "undefined" && threadId.startsWith("thread_")) {
          throw new Error("Invalid ThreadId");
        }
        set({ threadId });
      },
      setFromExisting: (fromExisting) => set({ fromExisting }),
    }),
    { name: "store" },
  ),
);

export default useStore;
