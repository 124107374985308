import { PrismaOrganization } from "@/types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface OrganizationState {
  organization: PrismaOrganization | null;
  setOrganization: (organization: PrismaOrganization) => void;
}

const useOrganization = create<OrganizationState>()(
  persist(
    (set) => ({
      organization: null,
      setOrganization: (organization) => set(() => ({ organization })),
    }),
    {
      name: "organization",
    }
  )
);

export default useOrganization;
