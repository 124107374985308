import React, { ReactElement } from "react";

const translations = {
  "en-US": {
    bot_not_found: "Bot not found or the assistant is not configured",
    bot_rate_limit_exceeded:
      "Too many requests. The chatbot has reached the rate limit.",
    invalid_request: "Input validation failed",
    message_limit_exceeded:
      "The chatbot reached the monthly message limit and cannot respond to any new messages",
    llm_error: "Failed to generate response to query",
    internal_error: "Something went wrong. Internal server error.",
    transcription_limit_exceeded:
      "The chatbot reached the monthly transcription limit and cannot transcribe any new speech to text",
    synthesis_limit_exceeded:
      "The chatbot reached the monthly speech synthesis limit and cannot convert any new text to speech",
    transcription_error: "Failed to convert speech to text",
    synthesis_error: "Failed to synthesize speech from text",
    poweredByResinq: "Powered by Resinq",
    chatHistory: "History",
    newChat: "New Chat",
    listening: "Listening",
    startSpeaking: "Start Speaking",
    paused: "Paused",
    processing: "Processing",
    answering: "Answering",
    sendManually: "Click {{comp}} to send manually",
    play: "Click Play {{comp}} to activate",
    stop: "Click Stop {{comp}} to cancel",
    voice_chat_disabled: "Voice chat is not active for this chat bot",
    transcription_disabled: "Transcription is not active for this chat bot",
    bot_not_public: "Bot is private and not publicly available.",
  },
  "es-ES": {
    bot_not_found: "Bot no encontrado o el asistente no está configurado",
    bot_rate_limit_exceeded:
      "Demasiadas solicitudes. El chatbot ha alcanzado el límite de solicitudes.",
    invalid_request: "La validación de entrada falló",
    message_limit_exceeded:
      "El chatbot alcanzó el límite mensual de mensajes y no puede responder a nuevos mensajes",
    llm_error: "No se pudo generar respuesta a la consulta",
    internal_error: "Algo salió mal. Error interno del servidor.",
    transcription_limit_exceeded:
      "El chatbot alcanzó el límite mensual de transcripción y no puede transcribir ningún nuevo discurso a texto",
    synthesis_limit_exceeded:
      "El chatbot alcanzó el límite mensual de síntesis de voz y no puede convertir ningún nuevo texto a voz",
    transcription_error: "Error al convertir el discurso a texto",
    synthesis_error: "Error al sintetizar la voz a partir del texto",
    poweredByResinq: "Con tecnología de Resinq",
    chatHistory: "HISTORIAL DE CHAT",
    newChat: "Nuevo Chat",
    listening: "Escuchando",
    startSpeaking: "Comenzar a Hablar",
    paused: "Pausado",
    processing: "Procesando",
    answering: "Respondiendo",
    sendManually: "Haga clic en {{comp}} para enviar manualmente",
    play: "Haga clic en Play {{comp}} para activar",
    stop: "Haga clic en Stop {{comp}} para cancelar",
  },
  "zh-CN": {
    bot_not_found: "找不到机器人或助手未配置",
    bot_rate_limit_exceeded: "请求过多。聊天机器人已达到速率限制。",
    invalid_request: "输入验证失败",
    message_limit_exceeded: "聊天机器人达到了月度消息限制，无法回应任何新消息",
    llm_error: "无法生成查询的响应",
    internal_error: "出了点问题。内部服务器错误。",
    transcription_limit_exceeded:
      "聊天机器人达到了月度转录限制，无法将任何新语音转为文本",
    synthesis_limit_exceeded:
      "聊天机器人达到了月度语音合成限制，无法将任何新文本转为语音",
    transcription_error: "语音转文本失败",
    synthesis_error: "语音合成失败",
    poweredByResinq: "由 Resinq 提供技术支持",
    chatHistory: "聊天记录",
    newChat: "新聊天",
    listening: "正在听",
    startSpeaking: "开始说话",
    paused: "暂停",
    processing: "处理中",
    answering: "回答中",
    sendManually: "点击 {{comp}} 手动发送",
    play: "点击播放 {{comp}} 激活",
    stop: "点击停止 {{comp}} 取消",
  },
  "hi-IN": {
    bot_not_found: "बॉट नहीं मिला या सहायक कॉन्फ़िगर नहीं है",
    bot_rate_limit_exceeded:
      "बहुत अधिक अनुरोध। चैटबॉट ने दर सीमा को पार कर लिया है।",
    invalid_request: "इनपुट सत्यापन विफल",
    message_limit_exceeded:
      "चैटबॉट ने मासिक संदेश सीमा को पार कर लिया है और नए संदेशों का उत्तर नहीं दे सकता",
    llm_error: "प्रश्न के उत्तर उत्पन्न करने में विफल",
    internal_error: "कुछ गलत हो गया। आंतरिक सर्वर त्रुटि।",
    transcription_limit_exceeded:
      "चैटबॉट ने मासिक ट्रांसक्रिप्शन सीमा को पार कर लिया है और नए भाषण को पाठ में परिवर्तित नहीं कर सकता",
    synthesis_limit_exceeded:
      "चैटबॉट ने मासिक भाषण संश्लेषण सीमा को पार कर लिया है और नए पाठ को भाषण में परिवर्तित नहीं कर सकता",
    transcription_error: "भाषण को पाठ में बदलने में विफल",
    synthesis_error: "पाठ से भाषण संश्लेषण में विफल",
    poweredByResinq: "Resinq द्वारा संचालित",
    chatHistory: "चैट इतिहास",
    newChat: "नई चैट",
    listening: "सुन रहा है",
    startSpeaking: "बोलना शुरू करें",
    paused: "रुका हुआ",
    processing: "प्रसंस्करण",
    answering: "उत्तर दे रहा है",
    sendManually: "मैन्युअल रूप से भेजने के लिए {{comp}} पर क्लिक करें",
    play: "सक्रिय करने के लिए Play {{comp}} पर क्लिक करें",
    stop: "रद्द करने के लिए Stop {{comp}} पर क्लिक करें",
  },
  "ar-SA": {
    bot_not_found: "البوت غير موجود أو المساعد غير مُهيأ",
    bot_rate_limit_exceeded:
      "طلبات كثيرة جدًا. وصل الروبوت إلى الحد الأقصى للطلبات.",
    invalid_request: "فشل في التحقق من صحة الإدخال",
    message_limit_exceeded:
      "وصل الروبوت إلى الحد الأقصى الشهري للرسائل ولا يمكنه الرد على أي رسائل جديدة",
    llm_error: "فشل في إنشاء استجابة للاستعلام",
    internal_error: "حدث خطأ ما. خطأ في الخادم الداخلي.",
    transcription_limit_exceeded:
      "وصل الروبوت إلى الحد الأقصى الشهري للنصوص ولا يمكنه تحويل أي كلام جديد إلى نص",
    synthesis_limit_exceeded:
      "وصل الروبوت إلى الحد الأقصى الشهري لتوليف الكلام ولا يمكنه تحويل أي نص جديد إلى كلام",
    transcription_error: "فشل في تحويل الكلام إلى نص",
    synthesis_error: "فشل في توليف الكلام من النص",
    poweredByResinq: "بدعم من Resinq",
    chatHistory: "تاريخ المحادثة",
    newChat: "محادثة جديدة",
    listening: "الاستماع",
    startSpeaking: "ابدأ التحدث",
    paused: "متوقف مؤقتًا",
    processing: "جارٍ المعالجة",
    answering: "جارٍ الرد",
    sendManually: "انقر على {{comp}} للإرسال يدويًا",
    play: "انقر على تشغيل {{comp}} للتنشيط",
    stop: "انقر على إيقاف {{comp}} للإلغاء",
  },
  "pt-BR": {
    bot_not_found: "Bot não encontrado ou o assistente não está configurado",
    bot_rate_limit_exceeded:
      "Muitas solicitações. O chatbot atingiu o limite de solicitações.",
    invalid_request: "A validação de entrada falhou",
    message_limit_exceeded:
      "O chatbot atingiu o limite mensal de mensagens e não pode responder a novas mensagens",
    llm_error: "Falha ao gerar resposta à consulta",
    internal_error: "Algo deu errado. Erro interno do servidor.",
    transcription_limit_exceeded:
      "O chatbot atingiu o limite mensal de transcrição e não pode transcrever nenhum novo discurso para texto",
    synthesis_limit_exceeded:
      "O chatbot atingiu o limite mensal de síntese de fala e não pode converter nenhum novo texto em fala",
    transcription_error: "Falha ao converter fala em texto",
    synthesis_error: "Falha ao sintetizar fala a partir do texto",
    poweredByResinq: "Desenvolvido por Resinq",
    chatHistory: "HISTÓRICO DE CHAT",
    newChat: "Novo Chat",
    listening: "Ouvindo",
    startSpeaking: "Começar a Falar",
    paused: "Pausado",
    processing: "Processando",
    answering: "Respondendo",
    sendManually: "Clique em {{comp}} para enviar manualmente",
    play: "Clique em Play {{comp}} para ativar",
    stop: "Clique em Stop {{comp}} para cancelar",
  },
  "ru-RU": {
    bot_not_found: "Бот не найден или ассистент не настроен",
    bot_rate_limit_exceeded:
      "Слишком много запросов. Чатбот достиг предела запросов.",
    invalid_request: "Ошибка валидации ввода",
    message_limit_exceeded:
      "Чатбот достиг месячного лимита сообщений и не может отвечать на новые сообщения",
    llm_error: "Не удалось сгенерировать ответ на запрос",
    internal_error: "Что-то пошло не так. Внутренняя ошибка сервера.",
    transcription_limit_exceeded:
      "Чатбот достиг месячного лимита транскрипций и не может транскрибировать новые речи в текст",
    synthesis_limit_exceeded:
      "Чатбот достиг месячного лимита синтеза речи и не может конвертировать новый текст в речь",
    transcription_error: "Не удалось конвертировать речь в текст",
    synthesis_error: "Не удалось синтезировать речь из текста",
    poweredByResinq: "Работает на Resinq",
    chatHistory: "ИСТОРИЯ ЧАТА",
    newChat: "Новый Чат",
    listening: "Слушаю",
    startSpeaking: "Начать Говорить",
    paused: "Пауза",
    processing: "Обработка",
    answering: "Отвечаю",
    sendManually: "Нажмите {{comp}} для отправки вручную",
    play: "Нажмите Play {{comp}} для активации",
    stop: "Нажмите Stop {{comp}} для отмены",
  },
  "ja-JP": {
    bot_not_found: "ボットが見つからないか、アシスタントが設定されていません",
    bot_rate_limit_exceeded:
      "リクエストが多すぎます。チャットボットがレート制限に達しました。",
    invalid_request: "入力検証に失敗しました",
    message_limit_exceeded:
      "チャットボットが月間メッセージ制限に達し、新しいメッセージに返信できません",
    llm_error: "クエリへの応答の生成に失敗しました",
    internal_error: "何かがうまくいきませんでした。内部サーバーエラー。",
    transcription_limit_exceeded:
      "チャットボットが月間文字起こし制限に達し、新しい音声をテキストに変換できません",
    synthesis_limit_exceeded:
      "チャットボットが月間音声合成制限に達し、新しいテキストを音声に変換できません",
    transcription_error: "音声をテキストに変換できませんでした",
    synthesis_error: "テキストから音声への合成に失敗しました",
    poweredByResinq: "Resinq提供",
    chatHistory: "チャット履歴",
    newChat: "新しいチャット",
    listening: "リスニング",
    startSpeaking: "話し始める",
    paused: "一時停止",
    processing: "処理中",
    answering: "応答中",
    sendManually: "{{comp}}をクリックして手動で送信",
    play: "{{comp}}をクリックして再生",
    stop: "{{comp}}をクリックして停止",
  },
  "de-DE": {
    bot_not_found:
      "Bot nicht gefunden oder der Assistent ist nicht konfiguriert",
    bot_rate_limit_exceeded:
      "Zu viele Anfragen. Der Chatbot hat das Anfragelimit erreicht.",
    invalid_request: "Eingabevalidierung fehlgeschlagen",
    message_limit_exceeded:
      "Der Chatbot hat das monatliche Nachrichtenlimit erreicht und kann auf keine neuen Nachrichten antworten",
    llm_error: "Fehler beim Generieren der Antwort auf die Abfrage",
    internal_error: "Etwas ist schief gelaufen. Interner Serverfehler.",
    transcription_limit_exceeded:
      "Der Chatbot hat das monatliche Transkriptionslimit erreicht und kann keine neuen Sprache-zu-Text-Transkriptionen durchführen",
    synthesis_limit_exceeded:
      "Der Chatbot hat das monatliche Sprachsyntheselimit erreicht und kann keinen neuen Text in Sprache umwandeln",
    transcription_error: "Fehler bei der Umwandlung von Sprache in Text",
    synthesis_error: "Fehler bei der Sprachsynthese aus Text",
    poweredByResinq: "Powered by Resinq",
    chatHistory: "CHAT-VERLAUF",
    newChat: "Neuer Chat",
    listening: "Zuhören",
    startSpeaking: "Sprechen starten",
    paused: "Pausiert",
    processing: "Verarbeitung",
    answering: "Beantwortung",
    sendManually: "Klicken Sie auf {{comp}}, um manuell zu senden",
    play: "Klicken Sie auf Play {{comp}}, um zu aktivieren",
    stop: "Klicken Sie auf Stop {{comp}}, um abzubrechen",
  },
  "ko-KR": {
    bot_not_found: "봇을 찾을 수 없거나 어시스턴트가 설정되지 않았습니다",
    bot_rate_limit_exceeded:
      "요청이 너무 많습니다. 채팅 봇이 한도에 도달했습니다.",
    invalid_request: "입력 유효성 검사 실패",
    message_limit_exceeded:
      "채팅 봇이 월간 메시지 한도에 도달하여 새로운 메시지에 응답할 수 없습니다",
    llm_error: "쿼리에 대한 응답 생성 실패",
    internal_error: "문제가 발생했습니다. 내부 서버 오류.",
    transcription_limit_exceeded:
      "채팅 봇이 월간 전사 한도에 도달하여 새로운 음성을 텍스트로 전사할 수 없습니다",
    synthesis_limit_exceeded:
      "채팅 봇이 월간 음성 합성 한도에 도달하여 새로운 텍스트를 음성으로 변환할 수 없습니다",
    transcription_error: "음성을 텍스트로 변환하는 데 실패했습니다",
    synthesis_error: "텍스트에서 음성을 합성하는 데 실패했습니다",
    poweredByResinq: "Resinq 제공",
    chatHistory: "채팅 기록",
    newChat: "새로운 채팅",
    listening: "듣기",
    startSpeaking: "말하기 시작",
    paused: "일시 정지",
    processing: "처리 중",
    answering: "응답 중",
    sendManually: "{{comp}} 클릭하여 수동으로 전송",
    play: "활성화하려면 Play {{comp}} 클릭",
    stop: "취소하려면 Stop {{comp}} 클릭",
  },
  "fr-FR": {
    bot_not_found: "Bot non trouvé ou l'assistant n'est pas configuré",
    bot_rate_limit_exceeded:
      "Trop de demandes. Le chatbot a atteint la limite de demandes.",
    invalid_request: "Échec de la validation de l'entrée",
    message_limit_exceeded:
      "Le chatbot a atteint la limite mensuelle de messages et ne peut pas répondre aux nouveaux messages",
    llm_error: "Échec de la génération de réponse à la requête",
    internal_error: "Une erreur est survenue. Erreur interne du serveur.",
    transcription_limit_exceeded:
      "Le chatbot a atteint la limite mensuelle de transcription et ne peut pas transcrire de nouveaux discours en texte",
    synthesis_limit_exceeded:
      "Le chatbot a atteint la limite mensuelle de synthèse vocale et ne peut pas convertir de nouveaux textes en parole",
    transcription_error: "Échec de la conversion du discours en texte",
    synthesis_error: "Échec de la synthèse vocale à partir du texte",
    poweredByResinq: "Propulsé par Resinq",
    chatHistory: "HISTORIQUE DES DISCUSSIONS",
    newChat: "Nouvelle Discussion",
    listening: "Écoute",
    startSpeaking: "Commencer à Parler",
    paused: "En pause",
    processing: "Traitement",
    answering: "En cours de réponse",
    sendManually: "Cliquez sur {{comp}} pour envoyer manuellement",
    play: "Cliquez sur Play {{comp}} pour activer",
    stop: "Cliquez sur Stop {{comp}} pour annuler",
  },
  "tr-TR": {
    bot_not_found: "Bot bulunamadı veya asistan yapılandırılmadı",
    bot_rate_limit_exceeded:
      "Çok fazla istek. Sohbet botu hız sınırına ulaştı.",
    invalid_request: "Giriş doğrulaması başarısız",
    message_limit_exceeded:
      "Sohbet botu aylık mesaj limitine ulaştı ve yeni mesajlara yanıt veremiyor",
    llm_error: "Sorguya yanıt oluşturulamadı",
    internal_error: "Bir şeyler yanlış gitti. İç sunucu hatası.",
    transcription_limit_exceeded:
      "Sohbet botu aylık transkripsiyon limitine ulaştı ve yeni konuşmayı metne dönüştüremiyor",
    synthesis_limit_exceeded:
      "Sohbet botu aylık konuşma sentezi limitine ulaştı ve yeni metni konuşmaya dönüştüremiyor",
    transcription_error: "Konuşmayı metne dönüştürme başarısız oldu",
    synthesis_error: "Metinden konuşma sentezi başarısız oldu",
    poweredByResinq: "Resinq tarafından desteklenmektedir",
    chatHistory: "Sohbet Geçmişi",
    newChat: "Yeni Sohbet",
    listening: "Dinleniyor",
    startSpeaking: "Konuşmaya Başla",
    paused: "Duraklatıldı",
    processing: "İşleniyor",
    answering: "Yanıtlanıyor",
    sendManually: "Manuel olarak göndermek için {{comp}} tıklayın",
    play: "Etkinleştirmek için {{comp}} Oynat'a tıklayın",
    stop: "İptal etmek için {{comp}} Durdur'a tıklayın",
    voice_chat_disabled: "Bu sohbet botu için sesli sohbet etkin değil",
    transcription_disabled: "Bu sohbet botu için transkripsiyon etkin değil",
    bot_not_public: "Bot özel ve genel olarak kullanılamaz.",
  },
} as const;

type Translations = typeof translations;
type LanguageCode = keyof Translations;
type TranslationKeys = keyof Translations[LanguageCode];

export default function useTranslations(placeholder: string = "{{comp}}") {
  const getLanguage = (): LanguageCode => {
    if (typeof window === "undefined") return "en-US";
    return window.navigator.language as LanguageCode;
  };

  const translate = (
    key: TranslationKeys,
    options?: {
      comp?: ReactElement;
      message?: string;
    },
  ): ReactElement => {
    const language = getLanguage();
    const translation =
      translations[language]?.[key] || translations["en-US"][key];
    const { comp, message } = options || {};
    if (!translation) {
      return <p>{message}</p>;
    }

    const translatedParts = translation.split(placeholder);

    return (
      <>
        {translatedParts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < translatedParts.length - 1 && comp}
          </React.Fragment>
        ))}
      </>
    );
  };

  function getTranslation(key: TranslationKeys, message?: string) {
    const language =
      translations[getLanguage()][key] || translations["en-US"][key];
    if (language) return language;
    else return message ?? "Unknown error";
  }

  return { translate, getTranslation };
}
