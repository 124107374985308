import { create } from "zustand";
import { canDowngradePlan } from "@/lib/utils";

export type DowngradeWarning = ReturnType<typeof canDowngradePlan>;

interface State {
  showConfirmation: boolean;
  setShowConfirmation: (show: boolean) => void;
  downgradeData: DowngradeWarning | null;
  setDowngradeData: (data: DowngradeWarning | null) => void;
  openDowngradeAlert: boolean;
  setOpenDowngradeAlert: (open: boolean) => void;
  priceId: string | null;
  setPriceId: (priceId: string | null) => void;
}

const usePlanChange = create<State>()((set) => ({
  priceId: null,
  showConfirmation: false,
  downgradeData: null,
  openDowngradeAlert: false,
  setPriceId: (priceId) => set({ priceId: priceId }),
  setOpenDowngradeAlert: (open) => set({ openDowngradeAlert: open }),
  setShowConfirmation: (show) => {
    set({ showConfirmation: show });
    if (!show) {
      set({ priceId: null });
    }
  },
  setDowngradeData: (data) => set({ downgradeData: data }),
}));

export default usePlanChange;
