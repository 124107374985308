import {
  getAccountInfo,
  getBot,
  getBots,
  getBotUIPreferences,
  getOrganization,
  getOrganizations,
  removeCookie,
  setCookie,
} from "@/actions";
import {
  cancelSubscriptionAtPeriodEnd,
  createCheckoutSession,
} from "@/actions/billing";
import { getInvitation, getRoleDefinitions } from "@/actions/membership";
import type { UsageOrLimit } from "@/lib/redis-account";
import useTranslations from "@/lib/use-translations";
import {
  actionWrapper,
  dateFormat,
  generateGradientStyle,
  getBotAvatarPicturePath,
  getBotIconPicturePath,
  getColor,
  getErrorMessage,
  HeadingNode,
  removeCookie as removeCookieClient,
} from "@/lib/utils";
import { BotUIFormSchemaType } from "@/schemas/bot-ui-schema";
import useStore from "@/store/use-store";
import useMessages from "@/store/useMessage";
import useOrganization from "@/store/useOrganization";
import usePlanChange, { DowngradeWarning } from "@/store/usePlanChange";
import {
  BotWithUIAndAssistantAndOrganization,
  CreateCheckoutSessionParams,
  Override,
  PermissionTypes,
  PrismaBot,
} from "@/types";
import { BotLeadCapture, FillType, Permission } from "@prisma/client";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { colord } from "colord";
import _ from "lodash";
import throttle from "lodash/throttle";
import {
  AudioLines,
  BellIcon,
  Blocks,
  BotIcon,
  CreditCard,
  GlobeIcon,
  MessageCircleIcon,
  Receipt,
  SettingsIcon,
  ShieldCheckIcon,
  User,
  UsersIcon,
  WebhookIcon,
} from "lucide-react";
import { signIn, useSession } from "next-auth/react";
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";

export function useAccountSettingsNavigation() {
  const canRead = useRoleAuth("billing", "READ");
  const canTeamRead = useRoleAuth("team", "READ");

  const navigationItems = [
    {
      name: "Account",
      route: `/account`,
      icon: User,
      className: "size-5",
      description:
        "Manage your personal account settings and update your profile.",
      hide: false,
    },
    {
      name: "Members",
      route: `/account/members`,
      icon: UsersIcon,
      className: "size-5",
      description:
        "View and manage team members, assign roles, and control permissions.",
      hide: !canTeamRead,
    },
    {
      name: "Billing",
      route: `/account/billing`,
      icon: CreditCard,
      className: "size-5",
      description:
        "Access your billing information, view invoices, and update payment methods.",
      hide: !canRead,
    },
    {
      name: "Plans",
      route: `/account/plans`,
      icon: Receipt,
      className: "size-5",
      description:
        "Explore and switch between available subscription plans to suit your needs.",
      hide: !canRead,
    },
  ];
  const pathname = usePathname();

  function getSettingPageByPath() {
    return navigationItems.find((item) => item.route === pathname);
  }

  return { navigationItems, getSettingPageByPath };
}

export function useChatSettingsNavigation() {
  const { orgId, botId } = useParams();
  const navigationItems = [
    {
      name: "General",
      route: `/org/${orgId}/bot/${botId}/settings`,
      icon: SettingsIcon,
      className: "size-5",
      description: "Manage your account and preferences",
      hide: false,
    },
    {
      name: "Model",
      route: `/org/${orgId}/bot/${botId}/settings/model`,
      icon: BotIcon,
      className: "size-5",
      description: "Configure your chatbot's language model",
      hide: false,
    },
    {
      name: "Voice",
      route: `/org/${orgId}/bot/${botId}/settings/voice`,
      icon: AudioLines,
      className: "size-5",
      description: "Configure your chatbot's voice settings",
      hide: false,
    },
    {
      name: "Chat Interface",
      route: `/org/${orgId}/bot/${botId}/settings/chat-interface`,
      icon: MessageCircleIcon,
      className: "size-5",
      description: "Customize the look and feel of your chatbot",
      hide: false,
    },
    {
      name: "Leads",
      route: `/org/${orgId}/bot/${botId}/settings/leads`,
      icon: UsersIcon,
      className: "size-5",
      description: "View and manage your chatbot's leads",
      hide: false,
    },
    {
      name: "Integrations",
      route: `/org/${orgId}/bot/${botId}/settings/integrations`,
      icon: Blocks,
      className: "size-5",
      description: "Configure your chatbot's integrations",
      hide: true,
    },
    {
      name: "Security",
      route: `/org/${orgId}/bot/${botId}/settings/security`,
      icon: ShieldCheckIcon,
      className: "size-5",
      description: "Manage your chatbot's security settings",
      hide: false,
    },
    {
      name: "Notifications",
      route: `/org/${orgId}/bot/${botId}/settings/notifications`,
      icon: BellIcon,
      className: "size-5",
      description: "Manage your chatbot's notification settings",
      hide: true,
    },
    {
      name: "Webhooks",
      route: `/org/${orgId}/bot/${botId}/settings/webhooks`,
      icon: WebhookIcon,
      className: "size-5",
      description: "Configure your chatbot's webhook integrations",
      hide: true,
    },
    {
      name: "Domains",
      route: `/org/${orgId}/bot/${botId}/settings/domains`,
      icon: GlobeIcon,
      className: "size-5",
      description: "Manage your chatbot's domain settings",
      hide: true,
    },
  ];
  const pathname = usePathname();

  function getSettingPageByPath() {
    return navigationItems.find((item) => item.route === pathname);
  }

  return { navigationItems, getSettingPageByPath };
}

export function useAuth(...params: Parameters<typeof useSession>) {
  const session = useSession(params[0]);

  return {
    ...session,
    isAuthenticated: session.status === "authenticated",
    isLoading: session.status === "loading",
    unAuthenticated: session.status === "unauthenticated",
    user: session?.data?.user,
  };
}

export function useOrganizations(userId: string) {
  return useQuery({
    queryKey: ["organizations", userId],
    enabled: !!userId,
    queryFn: () => actionWrapper(getOrganizations(userId)),
  });
}

export function useSingleOrganization(organizationId: string) {
  const { setOrganization } = useOrganization();
  const res = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: () => actionWrapper(getOrganization(organizationId)),
    enabled: !!organizationId,
    retry: 1,
  });

  useEffect(() => {
    if (res.isSuccess) {
      setOrganization(res.data);
    }
  }, [res.data]);
  return res;
}

export function useBots(organizationId: string) {
  return useQuery({
    queryKey: ["bots", organizationId],
    queryFn: () => actionWrapper(getBots(organizationId)),
    enabled: !!organizationId,
  });
}

export function useSingleBot<T extends PrismaBot>(botId: string) {
  return useQuery({
    queryKey: ["bot", botId],
    queryFn: () => actionWrapper(getBot<T>(botId)),
    enabled: !!botId,
    retry: 1,
  });
}
export function useAccountInfo(userId?: string) {
  return useQuery({
    queryKey: ["getAccountInfo"],
    queryFn: () => actionWrapper(getAccountInfo(userId)),
    staleTime: 0,
    gcTime: 0,
    refetchOnMount: true,
    refetchInterval: 30_000,
  });
}

export function useBotUI(botId: string) {
  return useQuery({
    queryKey: ["botUI", botId],
    queryFn: () => actionWrapper(getBotUIPreferences(botId)),
    enabled: !!botId,
    retry: 1,
  });
}

const feedbackIntervals = [3, 10, 20];
const complexMessageThreshold = 50;
const complexKeywords = [
  "help",
  "issue",
  "error",
  "solution",
  "problem",
  "troubleshoot",
  "question",
  "support",
  "complex",
  "difficult",
];

export const useFeedback = () => {
  const [interactionCount, setInteractionCount] = useState(0);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const { setShowFeedback, showFeedback } = useMessages();
  const isComplexMessage = (message: string) => {
    return (
      message.length > complexMessageThreshold ||
      complexKeywords.some((keyword) => message.includes(keyword))
    );
  };
  const shouldAskForFeedback = (message: string) => {
    if (_.isEmpty(message)) return false;
    const isFeedbackInterval = feedbackIntervals.includes(interactionCount + 1);
    const isComplexMessageInterval =
      (interactionCount + 1) % 5 === 0 && isComplexMessage(message);
    return isFeedbackInterval || isComplexMessageInterval;
  };

  const onMessageSent = (message: string) => {
    setInteractionCount((prev) => prev + 1);
    if (shouldAskForFeedback(message)) {
      console.log("should ask for feedback");
      setShowFeedback(true);
      setFeedbackCount((prev) => prev + 1);
    } else {
      setShowFeedback(false);
    }
  };

  const resetFeedbackPrompt = () => {
    setShowFeedback(false);
  };
  return {
    interactionCount,
    feedbackCount,
    onMessageSent,
    resetFeedbackPrompt,
  };
};

export function useStickyFixed<T extends HTMLElement>(
  active: boolean,
  {
    topOffset = 0,
  }: {
    topOffset?: number | string;
  } = {},
) {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const element = ref.current;
      const rect = element.getBoundingClientRect();
      element.style.position = active ? "fixed" : "static";
      element.style.top = active ? topOffset.toString() : "auto";
      element.style.left = active ? `${rect.left}px` : "auto";
      element.style.right = active
        ? `${window.innerWidth - rect.right}px`
        : "auto";
      element.style.zIndex = "49";
    }
  }, [active]);

  return ref;
}

interface UseLeadCaptureOptions {
  botLeadCapture: BotLeadCapture;
  messages: any[];
  loading: boolean;
}

export const useLeadCapture = ({
  botLeadCapture,
  messages,
  loading,
}: UseLeadCaptureOptions) => {
  const { showLeadCapture, setShowLeadCapture } = useMessages();
  const { fromExisting, threadId } = useStore();

  useEffect(() => {
    if (
      !botLeadCapture?.enabled ||
      showLeadCapture === false ||
      fromExisting ||
      !threadId
    ) {
      return;
    }

    if (messages.length > 0 && !loading) {
      let timer: number | null = null;
      const messagesCount = messages.filter(
        (message) => message.role === "user",
      ).length;

      if (botLeadCapture.duration_enabled && messagesCount > 0) {
        timer = window.setTimeout(() => {
          setShowLeadCapture(true);
        }, botLeadCapture.duration_value * 1000);
      }

      if (
        botLeadCapture.message_enabled &&
        messagesCount >= botLeadCapture.message_count
      ) {
        setShowLeadCapture(true);
      }
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [botLeadCapture, messages, loading]);
};

export const useRoleAuth = (key: PermissionTypes, permission: Permission) => {
  const { orgId } = useParams<{ orgId: string }>();
  const { data: orgFromDB } = useSingleOrganization(orgId);
  const { organization: orgFromState } = useOrganization();

  const organization = orgFromState ?? orgFromDB;

  const { data, refetch } = useQuery({
    queryKey: ["roleDefinitions", organization?.id ?? orgId],
    queryFn: () => actionWrapper(getRoleDefinitions()),
    retryDelay: 1_000,
    gcTime: 0,
    staleTime: 0,
    refetchOnMount: "always",
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: "always",
    retry: 3,
  });

  useEffect(() => {
    if (!data) refetch();
  }, [data]);

  const permissionSet = data?.find((p) => p.role === organization?.role);
  return permissionSet?.[key].includes(permission);
};
export function useCurrentAnchor() {
  const [currentAnchor, setCurrentAnchor] = useState<string | null>(null);

  useEffect(() => {
    const mdxContainer: HTMLElement | null = document.querySelector(
      "[data-mdx-container]",
    );
    if (!mdxContainer) return;

    const offsetTop = mdxContainer.offsetTop - 1;

    const observer = new IntersectionObserver(
      (entries) => {
        let currentEntry = entries[0];
        if (!currentEntry) return;

        const offsetBottom =
          (currentEntry.rootBounds?.height || 0) * 0.3 + offsetTop;

        for (let i = 1; i < entries.length; i++) {
          const entry = entries[i];
          if (!entry) break;

          if (
            entry.boundingClientRect.top <
              currentEntry.boundingClientRect.top ||
            currentEntry.boundingClientRect.bottom < offsetTop
          ) {
            currentEntry = entry;
          }
        }

        let target: Element | undefined = currentEntry.target;

        // if the target is too high up, we need to find the next sibling
        while (target && target.getBoundingClientRect().bottom < offsetTop) {
          target = siblings.get(target)?.next;
        }

        // if the target is too low, we need to find the previous sibling
        while (target && target.getBoundingClientRect().top > offsetBottom) {
          target = siblings.get(target)?.prev;
        }
        if (target) setCurrentAnchor(target.getAttribute("href"));
      },
      {
        threshold: 1,
        rootMargin: `-${offsetTop}px 0px 0px 0px`,
      },
    );

    const siblings = new Map();

    const anchors = mdxContainer?.querySelectorAll("[data-mdx-heading]");
    anchors.forEach((anchor) => observer.observe(anchor));

    return () => {
      observer.disconnect();
    };
  }, []);

  return currentAnchor?.replace("#", "");
}

export const useUsageDetails = () => {
  const { data: accountInfo } = useAccountInfo();
  const plan = accountInfo?.Plan;

  if (!plan) {
    return {
      hasOrganizationLimit: false,
      hasBotLimit: false,
      hasMessageLimit: false,
      hasTeamMemberLimit: false,
    };
  }

  const isUnlimited = (limit: number) => limit === -1;

  const currents = {
    botCount: accountInfo?.chatbot_count ?? 0,
    messageCount: accountInfo?.message_count ?? 0,
    organizationCount: accountInfo?.organization_count ?? 0,
    teamMemberCount: accountInfo?.team_member_count ?? 0,
  };

  return {
    hasOrganizationLimit:
      isUnlimited(plan.org_limit) ||
      plan.org_limit > currents.organizationCount,
    hasBotLimit:
      isUnlimited(plan.bot_limit) || plan.bot_limit > currents.botCount,
    hasMessageLimit:
      isUnlimited(plan.message_limit) ||
      plan.message_limit > currents.messageCount,
    hasTeamMemberLimit:
      isUnlimited(plan.member_limit) ||
      plan.member_limit > currents.teamMemberCount,
  };
};

export function useWindowSize(): {
  width: number;
  height: number;
  isMobile: boolean;
  isDesktop: boolean;
} {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  // Memoize the result of the calculations using useMemo
  const memoizedWindowSize = useMemo(() => {
    return {
      ...windowSize,
      isMobile: typeof windowSize?.width === "number" && windowSize.width < 768,
      isDesktop:
        typeof windowSize?.width === "number" && windowSize.width >= 768,
    };
  }, [windowSize]);

  return memoizedWindowSize;
}

export function useSubscribe() {
  const router = useRouter();
  const { setDowngradeData, setOpenDowngradeAlert } = usePlanChange();

  return useMutation({
    mutationFn: (req: CreateCheckoutSessionParams) => {
      return actionWrapper(createCheckoutSession(req));
    },
    onSuccess: (data) => {
      if (typeof data === "boolean") {
        toast.success("Subscription updated successfully");
        location.href = "/account/billing";
      } else {
        router.push(data.url);
      }
    },
    onError: (error, { priceId }) => {
      try {
        const data: DowngradeWarning = JSON.parse(getErrorMessage(error));
        setDowngradeData(data);
        setOpenDowngradeAlert(true);
      } catch {
        toast.error(getErrorMessage(error));
      }
    },
  });
}

export function usePauseSubscription() {
  return useMutation({
    mutationFn: () => {
      return actionWrapper(cancelSubscriptionAtPeriodEnd());
    },
    onSuccess: (endDate) => {
      toast.success(
        `Your subscription will be cancelled at the end of this billing period. (${dateFormat(new Date(endDate * 1000))})`,
      );
    },
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
}

// Hook adapted from https://nickymeuleman.netlify.app/blog/table-of-contents
export function useActiveSlug(headers: HeadingNode[]) {
  const [activeSlug, setActiveSlug] = useState(``);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSlug(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` },
    );

    const idCallback = (nodes: HeadingNode[]) => {
      const elements: HTMLElement[] = [];

      nodes.forEach((node) => {
        if (node.children.length > 0) {
          elements.push(...idCallback(node.children));
        }

        const { slug: id } = node;
        const element = document.getElementById(id);

        if (!element) {
          throw new Error(`Cannot find heading with id: ${id}`);
        }

        elements.push(element);
      });

      return elements;
    };

    const elements = idCallback(headers);

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [headers]);

  return useMemo(() => activeSlug, [activeSlug]);
}

// TOC가 보여지는 최소 창 너비 - xl(1280px)
const minWidthForTOC = 1280;

export function useToc() {
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.innerWidth < minWidthForTOC) {
        return;
      }

      const headings = Array.from(
        document.querySelectorAll<HTMLElement>(".mdx h2, .mdx h3"),
      );

      let currentActiveId = "";

      for (const heading of headings) {
        if (window.scrollY >= heading.offsetTop) {
          currentActiveId = heading.id;
        } else {
          break;
        }
      }

      setActiveId(currentActiveId);
    }, 25);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return activeId;
}

export function useLimits(accountId?: string) {
  return useQuery<{ limits: UsageOrLimit }>({
    enabled: !!accountId,
    queryKey: ["account-limits", accountId],
    gcTime: 0,
    queryFn: async () => {
      const res = await axios.get(`/api/account/${accountId}?mode=limits`);
      return res.data;
    },
  });
}
export function useUsages(accountId?: string) {
  return useQuery<{ usages: UsageOrLimit }>({
    enabled: !!accountId,
    queryKey: ["account-usages", accountId],
    gcTime: 0,
    staleTime: 0,
    queryFn: async () => {
      const res = await axios.get(`/api/account/${accountId}?mode=usages`);
      return res.data;
    },
  });
}

export function useBotStyles(botUIOptions: Partial<BotUIFormSchemaType>) {
  const textColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0.5, 0.8),
    [botUIOptions.botPrimaryColor],
  );

  const borderColor = useMemo(
    () => getColor(botUIOptions.chatBgColor!, 0.15, 0.2),
    [botUIOptions.chatBgColor],
  );

  const hoverColor = useMemo(
    () => getColor(botUIOptions.chatBgColor!, 0.05, 0.1),
    [botUIOptions.chatBgColor],
  );

  const btnHoverColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0.15, 0.2),
    [botUIOptions.botPrimaryColor],
  );
  const transcribeBgColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0, 0.6),
    [botUIOptions.botPrimaryColor],
  );

  const botVoiceColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0.6, 0.1),
    [botUIOptions.botPrimaryColor],
  );

  const botVoiceLoadingColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0.4, 0.3),
    [botUIOptions.botPrimaryColor],
  );

  const userVoiceColor = useMemo(
    () => getColor(botUIOptions.botPrimaryColor!, 0.5, 0.2),
    [botUIOptions.botPrimaryColor],
  );

  const feedBackBtnHoverColor = useMemo(
    () => getColor(botUIOptions.botMessageBackgroundColor!, 0.15, 0.2),
    [botUIOptions.botMessageBackgroundColor],
  );

  const inputTextColor = useMemo(
    () => (colord(botUIOptions.chatBgColor!).isLight() ? "#2F2F31" : "#FEFEFF"),
    [botUIOptions.chatBgColor],
  );

  const gradientStyle = useMemo(
    () =>
      botUIOptions.botFillType !== FillType.SOLID
        ? {
            background: generateGradientStyle({
              fromColor: botUIOptions.botPrimaryColor!,
              toColor: botUIOptions.botSecondaryColor!,
              direction: botUIOptions.botGradientDirection!,
              stop: botUIOptions.gradientColorStop!,
              linear: botUIOptions.botFillType === FillType.GRADIENT_LINEAR,
            }),
          }
        : {},
    [
      botUIOptions.botPrimaryColor,
      botUIOptions.botSecondaryColor,
      botUIOptions.botGradientDirection,
      botUIOptions.botFillType,
      botUIOptions.gradientColorStop,
    ],
  );

  return {
    inputTextColor,
    textColor,
    borderColor,
    btnHoverColor,
    feedBackBtnHoverColor,
    gradientStyle,
    transcribeBgColor,
    botVoiceColor,
    botVoiceLoadingColor,
    userVoiceColor,
    hoverColor,
  };
}

export function useBotStylesFromUI(
  bot: BotWithUIAndAssistantAndOrganization,
  options?: Override,
) {
  const botUI = bot.BotUI.at(0)!;
  return useMemo(() => {
    const data: Partial<BotUIFormSchemaType> = {
      botPrimaryColor: options?.botPrimaryColor ?? botUI.bot_primary_color,
      botSecondaryColor:
        options?.botSecondaryColor ?? botUI.bot_secondary_color!,
      botGradientDirection:
        (options?.botGradientDirection as any) ?? botUI.bot_gradient_direction!,
      gradientColorStop:
        options?.gradientColorStop ?? botUI.gradient_color_stop!,
      botFillType: (options?.botFillType as any) ?? botUI.bot_fill_type,
      headerHeight: (options?.headerHeight as any) ?? botUI.header_height,
      avatarShape: (options?.avatarShape as any) ?? botUI.avatar_shape,
      displayName: options?.displayName ?? botUI.display_name,
      avatar: options?.avatar ?? getBotAvatarPicturePath(botUI?.avatar ?? ""),
      icon: options?.icon ?? getBotIconPicturePath(botUI?.icon ?? ""),
      keepChatHistory: options?.keepChatHistory ?? botUI.keep_chat_history,
      botMessageBackgroundColor:
        options?.botMessageBackgroundColor ??
        botUI.bot_message_background_color,
      chatBgColor: options?.chatBgColor ?? botUI.chat_bg_color,
      botMessageTextColor:
        options?.botMessageTextColor ?? botUI.bot_message_text_color,
      userMessageBackgroundColor:
        options?.userMessageBackgroundColor ??
        botUI.user_message_background_color,
      userMessageTextColor:
        options?.userMessageTextColor ?? botUI.user_message_text_color,
      showFooter: botUI.show_footer,
      footer: options?.footerText ?? botUI.footer_text,
      footerBgColor: options?.footerBgColor ?? botUI.footer_bg_color,
      footerLink: options?.footerLink ?? botUI.footer_link,
      footerTextColor: options?.footerTextColor ?? botUI.footer_text_color,
      showFeedback: options?.showFeedback ?? botUI.show_feedback,
      showMessageTimestamp:
        options?.showMessageTimestamp ?? botUI.show_message_timestamp,
      messageLayout: (options?.messageLayout as any) ?? botUI.message_layout,
      autoShow: options?.showDelay ?? botUI.show_delay,
      hasShadow: options?.hasShadow ?? botUI.has_shadow,
      initialMessages: options?.initialMessages ?? botUI.initial_messages,
      theme: options?.theme ?? (botUI.theme as any),
    };

    return data;
  }, [botUI, bot, options]);
}

/**
 * @param url - The URL of the audio file
 * @param name - The unique name of the audio
 */
export function useAudio(url: string, { name }: { name: string }) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio(url));

  useEffect(() => {
    const audio = audioRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleStopOthers = (event: CustomEvent) => {
      if (isPlaying && event.detail !== name) stop();
    };

    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    window.addEventListener("stopAllAudio", handleStopOthers as EventListener);

    return () => {
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      window.removeEventListener(
        "stopAllAudio",
        handleStopOthers as EventListener,
      );
    };
  }, [isPlaying, name]);

  const play = () => {
    window.dispatchEvent(new CustomEvent("stopAllAudio", { detail: name }));
    return audioRef.current.play();
  };

  const pause = () => {
    audioRef.current.pause();
  };

  const stop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  return { isPlaying, play, pause, stop };
}

export function useInvitation() {
  const searchParams = useSearchParams();
  const token = searchParams.get("token");

  const { data: invitation, isPending } = useQuery({
    queryKey: ["getInvitation", { token }],
    queryFn: () => getInvitation({ token: token ?? "" }),
    enabled: searchParams.has("token"),
  });

  const hasError =
    (!invitation || new Date() > invitation.expires_at) && !isPending;

  const errorMessage = invitation
    ? "This invitation has expired"
    : "Invalid invitation";

  return {
    hasError,
    errorMessage,
    token,
  };
}

export function useAuthProcess() {
  const { token } = useInvitation();
  const searchParams = useSearchParams();
  let callbackUrl: string | undefined =
    searchParams.get("callbackUrl") || "/account?from=auth";

  const [selected, setSelected] = useState<
    "google" | "github" | "credentials"
  >();

  async function handleAuth({
    email,
    provider,
    password,
    redirect,
  }: {
    provider: "google" | "github" | "credentials";
    email?: string;
    password?: string;
    redirect?: boolean;
  }) {
    if (token) await setCookie("token", token);
    if (redirect === false) callbackUrl = undefined;
    return signIn(provider, { callbackUrl, email, password, redirect });
  }

  const mutation = useMutation({
    onMutate({ provider }) {
      removeCookieClient(["upgradeDialog", "exceededDialog"]);
      if (searchParams.has("redirect")) {
        setCookie("redirect", searchParams.get("redirect")!);
      }
      setSelected(provider);
    },
    mutationFn: handleAuth,
    onError: async (error) => {
      await removeCookie("token");
      console.error(error);
    },
  });

  return {
    ...mutation,
    selected,
    callbackUrl,
  };
}

export const useSendRequest = () => {
  const { getTranslation } = useTranslations();
  return async (path: string, options: RequestInit = {}) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`,
        {
          method: "POST",
          ...options,
        },
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(getTranslation(error.code));
      }

      return response;
    } catch (error) {
      if (error === "Aborted") throw new Error("Request aborted");
      throw error;
    }
  };
};
